import { useState, useEffect } from "react";
import Button from "../components/Input/Button";
import { easApi } from "../api/edgeAdministrationShell/easApi";
import { toast } from "react-toastify";
import { Heading, HeadingColor } from "../components/Typography/Heading";
import { InformationCircleIcon, PlusIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { useSearchParams } from "react-router-dom";



export function FTPBrowser({ deviceId }: any) {
  const [searchParams,] = useSearchParams()
  const endpoint = searchParams.get('endpoint');
  const [ftpData, setFtpData] = useState<any>();
  const [currentPath, setCurrentPath] = useState<any>("/");
  const [isLoadingFtp, setIsLoadingFtp] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [sessionId, setSessionId] = useState<string>("")


  useEffect(() => {
    setSessionId(deviceId + "-" + endpoint)
  }, [deviceId, endpoint]);


  const createFtpSession = async (host: any) => {
    try {
      const createSessionBody = {
        name: sessionId,
        host: host,
        user: username,
        pw: password,
        port: 21
      }

      if (endpoint === null) {
        return
      }

      setIsLoadingFtp(true)
      const response = await easApi.postWebFtpCreateSession(deviceId, createSessionBody)
      setIsLoadingFtp(false)
      toast.success(`Connected to ftp://${endpoint}:21 on device [${deviceId}]`)
      return response;
    } catch (error: any) {
      setFtpData([])
      setIsLoadingFtp(false)
      toast.error(`ERROR: Could not create FTP session: ${error.code} ${JSON.stringify(error.response.data.message)}`);
    }
  };

  const deleteFtpSession = async () => {
    try {
      setIsLoadingFtp(true)
      const deleteSessionBody = {
        name: sessionId
      }
      const response = await easApi.postWebFtpDeleteSession(deviceId, deleteSessionBody)
      setIsLoadingFtp(false)
      setFtpData([])
      toast.info(`Disconnected from ftp://${endpoint}:21 on device [${deviceId}]`)
      return response
    }
    catch (error: any) {
      setIsLoadingFtp(false)
      setFtpData([])
      toast.error(`ERROR: Could not delete FTP session: ${error.code} ${JSON.stringify(error.response.data.message)}`);
    }
  }

  const listFtpDirectory = async (sessionName: string, path: string) => {
    try {
      setIsLoadingFtp(true);
      const listBody = {
        "sessionName": sessionName,
        "path": path
      }
      const response = await easApi.postWebFtpList(deviceId, listBody)
      setIsLoadingFtp(false);
      return response;
    } catch (error: any) {
      setIsLoadingFtp(false);
      setFtpData([])
      toast.error(`ERROR: Could not list FTP directory: ${error.code} ${JSON.stringify(error.response.data.message)}`);
    }
  };

  const handleDirectoryClick = async (path: string) => {
    const directory = await listFtpDirectory(sessionId, path.replace("//", "/"));
    setCurrentPath(path);
    setFtpData(directory);
  };

  const handleReturnClick = async () => {
    try {
      const parentPath = currentPath.split('/').slice(0, -1).join('/') || '/';
      const directory = await listFtpDirectory(sessionId, parentPath);
      setCurrentPath(parentPath);
      setFtpData(directory);
    } catch (error) {
      setFtpData([])
    }
  };

  const downloadFile = async (sessionName: string, path: string, name: string) => {
    try {
      setIsLoadingFtp(true);
      const downloadBody = {
        sessionName: sessionName,
        path: path
      }
      // download file in browser -> looks a bit weird but chat GPT says its OK ;)
      const response = await easApi.postWebFtpReadFile(deviceId, downloadBody);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setIsLoadingFtp(false)
    } catch (error: any) {
      setIsLoadingFtp(false)
      toast.error(`ERROR: Could not download file: ${error.code} ${JSON.stringify(error.message)}`);
    }
  };

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setIsLoadingFtp(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sessionName', sessionId);
      formData.append('path', currentPath);

      await easApi.postWebFtpWriteFile(deviceId, formData);

      toast.success(`File uploaded successfully: ${file.name}`);
      await handleDirectoryClick(currentPath); // Refresh the directory view
    } catch (error: any) {
      console.log(error)
      if (error.request.status === 401){
        toast.warning(`Your user account does require FTP-write permissions for this operation`)
      }
      else{
        toast.error(`ERROR: Could not upload file: ${error.code} ${JSON.stringify(error.response.data.message)}`);
      }
    } finally {
      setIsLoadingFtp(false);
    }
  };

  const handleReloadClick = async () => {
    await handleDirectoryClick(currentPath); // Refresh the directory view
  };

  return (
    <div className="space-y-3">
      <Heading color={HeadingColor.Gray}><InformationCircleIcon className="w-7 h-7 mr-1" />FTP Server on [{endpoint}]</Heading>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 max-w-3xl ">
        <label>
          FTP Username:  <p></p>
          <input
            list="usernames"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <datalist id="usernames">
            <option value="ftp_service" />
            <option value="ftp_user" />
            <option value="ftp_data" />
            <option value="ftp_recipe" />
            <option value="ftp_mdata" />
            <option value="ADM-HMI" />
          </datalist>
        </label>
        <label>
          FTP Password: <p></p>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        {<Button processing={isLoadingFtp} onClick={() => createFtpSession(endpoint)}>Connect</Button>}
        {<Button processing={isLoadingFtp} onClick={() => deleteFtpSession()}>Disconnect</Button>}
      </div>
      <div className="flex space-x-2"> {<Button processing={isLoadingFtp} onClick={() => handleDirectoryClick("/")}>Browse Root</Button>}</div>

      {isLoadingFtp ? (
        <div className="flex justify-center items-center mt-4">
          <img src="https://th.bing.com/th/id/R.96ea68ce34478c213ca5c12e6d7c74ab?rik=%2f2pr47oLR7r6Dg&riu=http%3a%2f%2fblog.mebi.me%2fimg%2floading.gif&ehk=rL0fTCMpooy6WGoiYbSc78UA%2fwmm0lequAafhQwPaQw%3d&risl=&pid=ImgRaw&r=0" alt="Loading..." />
        </div>

      ) : (

        <>
          {ftpData && (
            <div>
              <div className="flex items-center space-x-4">
                <Heading color={HeadingColor.Gray}><InformationCircleIcon className="w-7 h-7 mr-1" />Current Directory [{currentPath}]</Heading>
                <button onClick={handleReloadClick}>
                  <ArrowPathIcon className="w-6 h-6 text-blue-500 hover:text-blue-700" />
                </button>
                <button onClick={() => document.getElementById('fileInput')?.click()}>
                  <PlusIcon className="w-6 h-6 text-blue-500 hover:text-blue-700" />
                </button>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
              </div>
              <ul>
                <li className="flex items-center">
                  <button
                    className="text-blue-500 hover:text-blue-700 text-xl flex items-center"
                    onClick={handleReturnClick}>
                    <ArrowLeftIcon className="w-5 h-5 mr-1" /> Return
                  </button>
                </li>
                {ftpData.map((item: any) => (

                  <li key={item.path}>
                    {item.type === 'dir' && (
                      <button
                        className="text-blue-500 hover:text-blue-700 text-l flex items-center"
                        onClick={() => handleDirectoryClick(item.path)}>
                        {'📁'}{item.name}
                      </button>
                    )}

                    {item.type === 'file' && (
                      <button
                        className="text-blue-500 hover:text-blue-700 text-l ml-2 items-center"
                        onClick={() => downloadFile(sessionId, item.path, item.name)}>
                        {'📄'}{item.name}
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  )
}
